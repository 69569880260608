export default {
	data() {
		return {
			pushRouterNum: 0
		};
	},
	computed: {
		isTabRouter() {
			const _path = this.$route.path;
			const result = this.tab.filter(item => {
				return item.path == _path;
			});
			return !!result.length;
		}
	},
	watch: {
		'$route.path': {
			handler() {
				this.activeTab = this.$route.path;
			},
			immediate: true
		}
	},
	created() {},
	mounted() {},
	methods: {
		tabChange(e) {
			if (this.$route.path != e) {
				this.$router.replace(e);
			}
		}
	}
}
